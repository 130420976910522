import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import Dashboard from './components/Dashboard/Dashboard'
import Landing from './views/Landing/Landing'
import { AuthProvider } from './auth/AuthProvider'

function App() {
  return (
    <div className="App">
      <Router>
        <div className="container">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/dashboard" element={
              <AuthProvider>
                <Dashboard />
              </AuthProvider>
            } />
          </Routes>
        </div>
      </Router>
    </div>
  )
}

export default App
