import React, { useState } from 'react'
import './Landing.css'
import palm from '../../assets/images/palm.jpg'
import money from '../../assets/images/money.jpg'
import Brittany_Joiner from '../../assets/images/Brittany_Joiner.avif'
import Daniel_Nikulshyn from '../../assets/images/Daniel_Nikulshyn.avif'
import hacker from '../../assets/images/hacker.avif'

function Landing() {
    const [data, setData] = useState({})

    const openDashboard = () => {
        window.location.pathname = '/dashboard';
    }

    const scrollTo = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='Landing'>
            <div className='Menu'>
                <div className='MenuItem' onClick={() => scrollTo('about')}>About</div>
                <div className='MenuItem' onClick={() => scrollTo('prices')}>Prices</div>
                <div className='MenuItem' onClick={() => scrollTo('feedbacks')}>Feedbacks</div>
            </div>
            <div className='Header'>
                <div className='HeaderContent'>
                    <h1 className='Title'>Code Vacations</h1>
                    <span className='Subtitle'>Unlock the power of our cutting-edge technology with our exclusive introductory offer! The first generation is on us - completely free of charge! And for every subsequent generation, you'll only pay a small fee.</span>
                    <button className='Button-Get-Started' onClick={() => openDashboard()}>Get Started</button>
                </div>
                <img className='Palm' alt='Code Vacations Image. Nocode REST-API generator. Welcomenocode' src={palm}/>
            </div>
            <div className='About' id="about">
                <div className='AboutTitle'>
                    About Code Vacations
                </div>
                <div className='AboutText'>
                    Welcome to Code Vacations, the ultimate nocode app for generating backend and frontend code in just a matter of minutes. With our innovative platform, you can transform days or even weeks of tedious coding work into just a few short minutes of easy, breezy work.
                </div>
                <div className='AboutText'>
                    Whether you're a developer, entrepreneur, or simply looking to streamline your workflow, Code Vacations is the perfect solution for anyone looking to save time, increase productivity, and take their coding skills to the next level.
                </div>
                <div className='AboutText'>
                    With our user-friendly interface and intuitive drag-and-drop functionality, you can easily create complex web applications, APIs, and more without the need for any coding experience. From startups to enterprise-level businesses, Code Vacations is designed to meet the needs of any user, regardless of their technical background.
                </div>
                <div className='AboutText'>
                    So why spend hours or days struggling with complex coding when you can take a vacation with Code Vacations? With our powerful platform, you can focus on what really matters - bringing your ideas to life and growing your business. Whether you're building a new project from scratch or looking to improve an existing application, Code Vacations has everything you need to succeed.
                </div>
                <div className='AboutText'>
                    So why wait? Start your coding vacation today with Code Vacations and see how easy and fun coding can be!
                </div>
            </div>
            <div className='Prices' id="prices">
                <div className='PricesTitle'>Prices</div>
                <div className='PriceContent'>
                    <div>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/e785e0181f1a23a30d9476038d9be91e9f6c63959b538eabbc51a1abc8898940383291eede695c3b8dfaa1829a9b57f5a2d0a16b0523580346c6b8fab67af14b/asset_icons/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/71d2a21895c80ade641c5e18d1cf2f7fa9c9ab47775ee6e771c98d51bd97419c9041dfb7c661f125a7f663ab7653534c16dca476dacb340197750ce378926c36/asset_icons/c23c607a3e4479ff21f66cfece0e096d673f847c46b873329ac1760dd72dc0a2.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/93a4303d1b0410b79bb1feac01020e4e7bdf8e6ece68282d0af2c7d0b481c5f5c44c0cec1d7071ae8f84674dbd139e290d50a038a6a4c1bbc856ec0871b5f3e2/asset_icons/3af4b33bde3012fd29dd1366b0ad737660f24acc91750ee30a034a0679256d0b.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/90184cca292578d533bb00d9ee98529b889c15126bb120582309286b9129df9886781b30c85c21ee9cae9f2db6dc11e88633c7361fdd1ba5046ea444e101ae15/asset_icons/ebc24b163bf1f58a9732a9a1d2faa5b2141b041d754ddc2260c5e76edfed261e.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/3803f30367bb3972e192cd3fdd2230cd37e6d468eab12575a859229b20f12ff9c994d2c86ccd7bf9bc258e9bd5e46c5254283182f70caf4bd02cc4f8e3890d82/asset_icons/1597d628dd19b7885433a2ac2d7de6ad196c519aeab4bfe679706aacbf1df78a.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/dbb4b4983bde81309ddab83eb598358eb44375b930b94687ebe38bc22e52c3b2125258ffb8477a5ef22e33d6bd72e32a506c391caa13af64c00e46613c3e5806/asset_icons/4113b082d21cc5fab17fc8f2d19fb996165bcce635e6900f7fc2d57c4ef33ae9.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/f018870b721574ef7f269b9fd91b36042dc05ebed4ae9dcdc340a1bae5b359e8760a8c224bc99466db704d10a3e23cf1f4cd1ff6f647340c4c9c899a9e6595cd/asset_icons/984a4fe2ba5b2c325c06e4c2f3ba3f1c1fef1f157edb3b8ebbfe234340a157a5.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/c14c8dc36c003113c898b56dfff649eb0ff71249fd7c8a9de724edb2dedfedde5562ba4a194db8433f2ef31a1e879af0727e6632751539707b17e66d63a9013b/asset_icons/a7309384448163db7e3e9fded23cd6ecf3ea6e1fb3800cab216acb7fc85f9563.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/41f6a93a3a222078c939115fc304a67c384886b7a9e6c15dcbfa6519dc45f6bb4a586e9c48535d099efa596dbf8a9dd72b05815bcd32ac650c50abb5391a5bd0/asset_icons/1f8489bb280fb0a0fd643c1161312ba49655040e9aaaced5f9ad3eeaf868eadc.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/3c15df5e2ac7d4abbe9499ed9335041f00c620f28e8de2f93474a9f432058742cdf4674bd43f309e69778a26969372310135be97eb183d91c492154176d455b8/asset_icons/9d67b728b6c8f457717154b3a35f9ddc702eae7e76c4684ee39302c4d7fd0bb8.png'/>
                        <img className='MoneyIcon' src='https://dynamic-assets.coinbase.com/085ce26e1eba2ccb210ea85df739a0ca2ef782747e47d618c64e92b168b94512df469956de1b667d93b2aa05ce77947e7bf1b4e0c7276371aa88ef9406036166/asset_icons/57f28803aad363f419a950a5f5b99acfd4fba8b683c01b9450baab43c9fa97ea.png'/>
                        <img className='MoneyIcon' src='https://static-assets.coinbase.com/p2p/l2/asset_network_combinations/v3/usdc-polygon.png'/>
                        <img className='MoneyIcon' src='https://static-assets.coinbase.com/p2p/l2/asset_network_combinations/v3/eth-polygon.png'/>
                    </div>
                    <div className='PricesText'>Begin your journey for free with the first generation, and for subsequent generations, a nominal fee of just $5 in your preferred cryptocurrency is all it takes. This affordable price grants you access to an array of advanced features for seamless code generation.</div>
                </div>
            </div>
            <div className='Feedbacks' id="feedbacks">
                <div className='FeedbacksTitle'>Feedbacks</div>
                <div className='FeedbacksContent'>
                    <div className='FeedbackItem'>
                        <img className='CommentImg' alt='Code Vacations Image. Nocode REST-API generator. Welcomenocode user' src={Brittany_Joiner}/>
                        <div className='Comment'>
                            <div className='CommentOwner'>Brittany Joiner</div>
                            <div className='CommentText'>Very cool! I made a note to try this in my next project that needs a DB!</div>
                        </div>
                    </div>
                    <div className='FeedbackItem'>
                        <img className='CommentImg' alt='Code Vacations Image. Nocode REST-API generator. Welcomenocode user' src={Daniel_Nikulshyn}/>
                        <div className='Comment'>
                            <div className='CommentOwner'>Daniel Nikulshyn</div>
                            <div className='CommentText'>By using an efficient approach, I was able to save weeks of my time and avoid the tedious task of monotonous work. As a result, I had the opportunity to take a much-needed vacation with my wife, creating unforgettable memories together. This experience not only rejuvenated me but also strengthened our bond. Trust me when I say that taking that break was the best decision I could have made.</div>
                        </div>
                    </div>
                    <div className='FeedbackItem'>
                        <img className='CommentImg' alt='Code Vacations Image. Nocode REST-API generator. Welcomenocode user' src={hacker}/>
                        <div className='Comment'>
                            <div className='CommentOwner'>Some student</div>
                            <div className='CommentText'>I completed a university course project in days and started my own projects with the program. Now, I use it regularly at work for increased productivity.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Footer'>
                <div className='FooterText'>© 2022-{new Date().getFullYear()} Code Vacations. All rights reserved.</div>
                <div className='Company'>Provided by <a href='https://www.welcomenocode.com'>Welcome NoCode</a></div>
                <div className='Links'>
                    <a href='https://www.welcomenocode.com/privacy-policy?app=codevacations'>Privacy Policy</a>
                    <a href='https://www.welcomenocode.com/refund-policy?app=codevacations'>Refund Policy</a>
                    <a href='https://www.welcomenocode.com/terms-and-conditions?app=codevacations'>Terms & Conditions</a>
                    <a href='https://www.welcomenocode.com?contacts'>Contacts</a>
                </div>
            </div>
        </div>
    )
}

export default Landing
  