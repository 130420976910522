import './PaymentMethods.css'
import LogoIcon from '../../assets/icons/logo.svg'
import React from 'react'

function PaymentMethods ({ close, payByCard, payByCrypto }) {
    return (
        <div className="PaymentPopup">
            <div className="PaymentMethods">
                <div className="XIcon"><div className="CardTitle"><img className="LogoIcon" src={LogoIcon}/> <div>Have a great vacation!</div></div> <img className="CloseIcon" onClick={() => close()} src="https://logowik.com/content/uploads/images/close1437.jpg"/></div>
                <div className="ChooseText">Choose a payment method</div>
                <button onClick={() => payByCrypto()}>Cryptocurrency payment</button>
                <button onClick={() => payByCard()}>Pay with a card</button>
                <div class="stock-monitor-container">
                    <div class="stock-monitor"></div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethods