import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Dashboard.css'
import LogoIcon from '../../assets/icons/logo.svg'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PaymentMethods from '../PaymentMethods/PaymentMethods'
import { Wayforpay } from './wayforpay'

async function downloadURI(url, name) {
    const resData = await axios.get(url);
    
    if (resData.data.success) {
        let link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', name);
        link.href = resData.data.url;
        document.body.appendChild(link);
        link.click();
        link.remove();
    } else {
        // error
        throw Error(resData.message)
    }
    
}

function Dashboard() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const auth_token = localStorage.getItem('token')

    const [columns, setColumns] = useState(Array)
    const [columnName, setColumnName] = useState('')
    const [columnType, setColumnType] = useState('String')
    const [isDark, setDark] = useState(false)
    const [isMenu, setMenu] = useState(false)
    const [isTable, setTable] = useState(false)
    const [tableName, setTableName] = useState('')
    const [columnIssue, setColumnIssue] = useState(false)
    const [tables, setTables] = useState(Array)
    const [required, setRequired] = useState('false')
    const [defaultValue, setDefaultValue] = useState('')
    const [usePagination, setUsePagination] = useState(params.use_pagination ? params.use_pagination === 'true' ? true : false : false)
    const [useLambda, setUseLambda] = useState(params.use_lambda ? params.use_lambda === 'true' ? true : false : false)
    const [useJWT, setUseJWT] = useState(params.jwt ? params.jwt === 'true' ? true : false : false)
    const [useExpress, setUseExpress] = useState(params.express ? params.express === 'true' ? true : false : true)
    const [database, setDatabase] = useState(params.database ? params.database : 'mysql')
    const [name, setName] = useState(params.name ? params.name : '')
    const [description, setDescription] = useState(params.description ? params.description : '')
    const [packageName, setPackageName] = useState(params.package ? params.package : '')
    const [author, setAuthor] = useState(params.author ? params.author : '')
    const [isShare, setShare] = useState(false)
    const [shareUrl, setShareUrl] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [isPay, setPay] = useState(false)
    const [isMobile, setMobile] = useState(false)
    const [isTrial, setTrial] = useState(false)
    const [isOpenPaymentsPopup, setOpenPaymentsPopup] = useState(false)

    useEffect(() => {
        fetch(
            'https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/user/profile?token=' + auth_token
        ).then(function (res) {
            return res.json()
        }).then(function (res) {
            if (res?.success) {
                localStorage.setItem('user_first_name', res.data.first_name)
                localStorage.setItem('user_last_name', res.data.last_name)
                localStorage.setItem('user_email', res.data.email)
                localStorage.setItem('user_id', res.data._id)
            }
        })

        fetch(
            `https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/trials?app=codevacations&token=${auth_token}`
        ).then(function (res) {
            return res.json()
        }).then(function (res) {
            if (res && !res.success) {
                axios.post(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/trials?app=codevacations&token=${auth_token}`,
                    {
                        app: "codevacations",
                        max_attempts: 1
                    }
                ).then(function(res_new_json) {
                    setTrial(res_new_json.data.is_expired)
                })
            } else if (res && res.success) {
                setTrial(res.data.is_expired)
            }
        }).catch((error) => {
            console.log("Trial error: ", error)
        })

        mobileCheck()
    }, [])

    const openLinkedin = () => {
        window.open('https://www.linkedin.com/company/welcomenocode')
    }

    const closeTableCard = () => {
        setTable(false)
        setColumnIssue(false)
        setColumns([])
        setColumnName('')
        setTableName('')
        setRequired('false')
        setDefaultValue('')
        setColumnType('String')
    }

    const addColumn = () => {
        if (columns.filter((column) => column.name == columnName).length > 0) {
            setColumnIssue('Such column already exists.')
        } else {
            if (columnName.trim() === '') {
                setColumnIssue('Please type the column name.')
            } else {
                columns.push({ 
                    name: columnName, 
                    type: columnType, 
                    required,
                    default_value: defaultValue
                })
                setColumnIssue(false)
                setColumns(columns)
                setColumnName('')
                setRequired('false')
                setDefaultValue('')
                setColumnType('String')
            }
        }
    }

    const removeColumn = (column) => {
        const newColumns = columns.filter(item => item.name !== column.name)
        setColumns(newColumns)
    }

    const removeTable = (table) => {
        const newTables = tables.filter(item => item.name !== table.name)
        setTables(newTables)
    }

    const saveTable = () => {
        if (tableName.trim() === '') {
            setColumnIssue('Please type the table name.')
        } else {
            if (tables.filter((table) => table.name == tableName).length > 0) {
                setColumnIssue('Such table already exists.')
            } else {
                if (columns.length === 0) {
                    setColumnIssue('Please creates the columns.')
                } else {
                    tables.push({
                        name: tableName,
                        columns
                    })
                    setTables(tables)
                    setTable(false)
                    setTableName('')
                    setColumnIssue(false)
                    setColumns([])
                    setColumnName('')
                    setRequired('false')
                    setDefaultValue('')
                    setColumnType('String')
                }
            }
        }
    }

    const sendGenerateReq = (payment_id) => {
        const data = JSON.parse(localStorage.getItem('data'))

        axios.post(process.env.REACT_APP_API_GENERATOR_PATH + `?token=${auth_token}${payment_id ? '&payment_id=' + payment_id : ''}`, {
            tables: data.tables,
            framework_type: data.framework_type,
            database_type: data.database,
            use_auth: data.use_auth,
            use_pagination: data.use_pagination,
            use_lambda: data.use_lambda,
            name: data.name,
            user_id: localStorage.getItem('user_id'),
            description: data.description,
            packageName: data.packageName,
            author: data.author
        })
        .then(function (response) {
            setTimeout(async () => {
                await downloadURI(`${process.env.REACT_APP_API_STORAGE_PATH}?token=${auth_token}&folder_name=${response.data.folder_name}&user_id=${localStorage.getItem('user_id')}${payment_id ? '&payment_id=' + payment_id : ''}`, 'codebase')
                localStorage.removeItem('data')
                localStorage.removeItem('payment_id')
                localStorage.removeItem('user_id')
                localStorage.removeItem('user_email')
                localStorage.removeItem('user_first_name')
                localStorage.removeItem('user_last_name')
                setTables([])
                setTable(false)
                setTableName('')
                setColumnIssue(false)
                setColumns([])
                setColumnName('')
                setDatabase('mysql')
                setRequired('false')
                setUseJWT(false)
                setUsePagination(false)
                setUseLambda(false)
                setDefaultValue('')
                setColumnType('String')
                setLoading(false)
                setPay(false)
                await axios.post(
                    `https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/trials/expire?app=codevacations&token=${auth_token}`,
                    {
                        app: "codevacations",
                        made_attempts: 1,
                        end_time: Date.now()
                    }
                )
            }, 1000)
        })
        .catch(function (error) {
            console.log('send Gen error:', error)
        })
    }

    const payByCard = async () => {
        setOpenPaymentsPopup(false)
        if (tables.length === 0) {
            toast.warning("Add database tables")
            return;
        } else {
            setLoading(true)

            const data = {
                tables,
                framework_type: "express",
                database: database,
                use_auth: useJWT,
                use_pagination: usePagination,
                use_lambda: useLambda,
                name,
                description,
                packageName,
                author,
                time: new Date().getTime()
            }

            localStorage.setItem('data', JSON.stringify(data))

            const product_id = 'PROD-63N65372RB222183P'
            
            const ex_trial = await axios.get(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/trials?app=codevacations&token=${auth_token}`)

            if (ex_trial && ex_trial?.data?.data) {
                if (ex_trial.data.data.is_expired) {
                    pay('credit-card')
                } else {
                    return sendGenerateReq(localStorage.getItem('payment_id'));
                }
            } else {
                toast.warning("Bad connection with server, try to reload the page")
            }
        }
    }

    const payByCrypto = async () => {
        setOpenPaymentsPopup(false)
        if (tables.length === 0) {
            toast.warning("Add database tables")
            return;
        } else {
            setLoading(true)

            const data = {
                tables,
                framework_type: "express",
                database: database,
                use_auth: useJWT,
                use_pagination: usePagination,
                use_lambda: useLambda,
                name,
                description,
                packageName,
                author,
                time: new Date().getTime()
            }

            localStorage.setItem('data', JSON.stringify(data))

            const product_id = 'PROD-63N65372RB222183P'
            
            const ex_trial = await axios.get(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/trials?app=codevacations&token=${auth_token}`)

            if (ex_trial && ex_trial?.data?.data) {
                if (ex_trial.data.data.is_expired) {
                    pay('cryptocurrency')
                } else {
                    return sendGenerateReq(localStorage.getItem('payment_id'));
                }
            } else {
                toast.warning("Bad connection with server, try to reload the page")
            }
        }
    }

    const generate = async () => {
        if (tables.length === 0) {
            toast.warning("Add database tables")
            return;
        } else {
            setLoading(true)

            const data = {
                tables,
                framework_type: "express",
                database: database,
                use_auth: useJWT,
                use_pagination: usePagination,
                use_lambda: useLambda,
                name,
                description,
                packageName,
                author,
                time: new Date().getTime()
            }

            localStorage.setItem('data', JSON.stringify(data))

            const product_id = 'PROD-63N65372RB222183P'
            
            const ex_trial = await axios.get(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/trials?app=codevacations&token=${auth_token}`)

            if (ex_trial && ex_trial?.data?.data) {
                if (ex_trial.data.data.is_expired) {
                    setOpenPaymentsPopup(true)
                } else {
                    return sendGenerateReq(localStorage.getItem('payment_id'));
                }
            } else {
                toast.warning("Bad connection with server, try to reload the page")
            }
        }
    }

    const pay = async (type) => {
        setPay(true)
        const product_id = 'PROD-63N65372RB222183P'

        axios.post(
            `https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/payments/${type}?token=${localStorage.getItem('token')}`,
            {
                "product_id": product_id,
                "app": "codevacations"
            }
        )
        .then((data) => {
            let payment_id

            if (data?.data?.data?.pageUrl) {
                window.open(data.data.data.pageUrl, '_blank')
                payment_id = data.data.data.invoiceId
                localStorage.setItem('payment_id', data.data.data.invoiceId)
            } else {
                toast.warning("Bad connection with server, try to reload the page")
            }
            
            setInterval(async () => {
                const new_ex_payment = await axios.get(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/payments?token=${auth_token}&app=codevacations&payment_id=${payment_id}`)

                if (new_ex_payment && new_ex_payment?.data?.success && new_ex_payment?.data?.data?.status === 'ACTIVE') {
                    return sendGenerateReq(payment_id)
                }
            }, 5000)
        })
        .catch((error) => { 
            setLoading(false)
            setPay(false)
            toast.warning("Bad connection with server, try to reload the page")
            console.log('Payment error:', error)
        })
    }

    const mobileCheck = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        setMobile(toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        }))
    }

    return (
        <div className='Dashboard'>
            { isOpenPaymentsPopup &&
                <PaymentMethods close={() => setOpenPaymentsPopup(false)} payByCard={() => payByCard()} payByCrypto={() => payByCrypto()}/>
            }
            { isMobile &&
                <div className='CardBackground'>
                    Mobile support is currently unavailable. 
                </div>
            }
            { !isMobile && isLoading &&
                <div className='CardBackground'>
                    <div className={isDark ? 'LoadingMessage dark' : 'LoadingMessage white'}>
                        <p>{ isPay ? 'Once you have opened the payment page in a new window, we kindly request that you proceed with the payment. As soon as the payment is successfully processed, the file containing the code base will be automatically downloaded. Please refrain from closing the tab, as doing so will result in the loss of all data.' : 'Please refrain from closing the tab, as doing so will result in the loss of all data. The file containing the code base will be automatically downloaded in a few seconds.'}</p>
                        <div className={isDark ? 'Loader-dark' : 'Loader-white'}/>
                    </div>
                </div>
            }
            { !isMobile && isShare &&
                <div className='CardBackground'>
                    <div className={isDark ? 'ShareCard dark' : 'ShareCard white'}>
                        <div className='ShareCardTop'>
                            <div className='ShareTitle'>Share your configuration</div>
                        </div>
                        <div className='ShareBody'>
                            <div className='ShareInfo'>Use this link to share the current configuration. Attributes can be removed from the URL if you want to rely on our defaults.</div>
                            <input className={isDark ? 'field dark' : 'field white'} value={shareUrl}/>
                        </div>
                        <div className='ShareCardBottom'>
                            <div className={isDark ? 'Button dark' : 'Button white'} onClick={() => setShare(false)}>CLOSE</div>
                        </div>
                    </div>
                </div>
            }
            { !isMobile && isMenu &&
                <div className='InfoCard'>
                    <div className='LeftMenu'>
                        <svg className='menu' onClick={() => setMenu(!isMenu)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                        <svg className='linkedin' onClick={() => openLinkedin()} fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="24px" height="24px"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                    </div>
                    <div className='InfoCardConstructor'>
                        <div className='Header'>
                            <img src={LogoIcon}/>
                            <span><span className='green'>code.</span>vacations</span>
                        </div>
                        <div className='Body'>
                            <div className='Info'>The project was created by <a href='https://www.linkedin.com/in/daniel-nikulshyn-741986189'>me</a> in 2022. At first it was my own utility to speed up my work on startups and then I decided it was a good tool to share with others. Despite this time, when there is a war in my country, I try to do something useful. I wish everyone easy coding and have a nice day ;)</div>
                            <div className='InfoButton'><a href='https://www.linkedin.com/company/welcomenocode'>Discover all updates</a></div>
                            <div className='InfoButton'><a href='https://www.linkedin.com/company/welcomenocode'>Discover all features</a></div>
                            <div className='InfoButton'><a href='https://www.welcomenocode.com/privacy-policy?app=codevacations'>Privacy policy</a></div>
                            <div className='InfoButton'><a href='https://www.welcomenocode.com/refund-policy?app=codevacations'>Refund policy</a></div>
                            <div className='InfoButton'><a href='https://www.welcomenocode.com/terms-and-conditions?app=codevacations'>Terms of service</a></div>
                        </div>
                    </div>
                </div>
            }
            { !isMobile && isTable &&
                <div className='CardBackground'>
                    <div className={isDark ? 'TableCard dark' : 'TableCard white'}>
                        <div className='CardHeader'>
                            <span>Add Table With Columns</span>
                            <svg onClick={() => closeTableCard()} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={isDark ? '#fff' : '#1b1f23'}><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                        </div>
                        { columnIssue &&
                            <div className='columnIssue'><b>Warning!</b> {columnIssue}</div>
                        }
                        <div className='ColumnHeader'>
                            <input onChange={(e) => setColumnName(e.target.value)} value={columnName} className={isDark ? 'field dark' : 'field white'} placeholder='column_name'/>
                            <select onChange={(e) => setColumnType(e.target.value)} value={columnType} className={isDark ? 'dark' : 'white'}>
                                <option value={'String'}>String</option>
                                <option value={'Number'}>Number</option>
                                <option value={'Array'}>Array</option>
                                <option value={'BigInt'}>BigInt</option>
                                <option value={'Boolean'}>Boolean</option>
                                <option value={'Date'}>Date</option>
                            </select>
                            <select onChange={(e) => setRequired(e.target.value)} value={required} className={isDark ? 'dark' : 'white'}>
                                <option value={'false'}>Is not required</option>
                                <option value={'true'}>Is required</option>
                            </select>
                            <input onChange={(e) => setDefaultValue(e.target.value)} value={defaultValue} className={isDark ? 'field dark' : 'field white'} placeholder='default_value'/>
                            <div onClick={() => addColumn()} className={isDark ? 'Button dark' : 'Button white'}>ADD</div>
                        </div>
                        <div className={isDark ? 'ColumnList dark' : 'ColumnList white'}>
                            { 
                                columns.length > 0 ? columns.map(column => {
                                    return (
                                        <div className='columnRow'>
                                            <span>{ column.name }</span> <span>{ column.type }</span> <span>{ column.required === 'true' ? 'Is required' : 'Is not required' }</span> <span>{ column.default_value }</span> <div className={isDark ? 'Button dark' : 'Button white'} onClick={() => removeColumn(column)}>REMOVE</div>
                                        </div>
                                    )    
                                }) : <p>no columns</p>
                            }
                        </div>
                        <div className='ColumnBottom'>
                            <input className={isDark ? 'field dark' : 'field white'} onChange={(e) => setTableName(e.target.value)} value={tableName} placeholder='table_name'/>
                            <div className={isDark ? 'Button dark' : 'Button white'} onClick={() => saveTable()}>SAVE</div>
                            <div className={isDark ? 'Button dark' : 'Button white'} onClick={() => closeTableCard()}>CANCEL</div>
                        </div>
                    </div>
                </div>
            }
            { !isMobile && <>
                <div className={isDark ? 'LeftMenu dark' : 'LeftMenu white'}>
                    <svg className='menu' onClick={() => setMenu(!isMenu)} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill={isDark ? '#fff' : '#1b1f23'}><path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                    </svg>
                    <svg className='linkedin' onClick={() => openLinkedin()} fill={isDark ? '#fff' : '#000000'} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="24px" height="24px"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                </div>
                <div className={isDark ? 'Constructor dark' : 'Constructor white'}>
                    <div className='Header'>
                        <img src={LogoIcon}/>
                        <span><span className='green'>code.</span>vacations</span>
                        <p className='green' onClick={() => window.open("https://www.welcomenocode.com/", '_blank')}>Provided by WNC</p>
                    </div>
                    <div className='Body'>
                        <div className='LeftBodyPart'>
                            <div className='ParamItem'>
                                <div className='ItemTitle'>
                                    Framework
                                </div>
                                <div className='ItemOptions'>
                                    <div className='CheckOption'><input checked={useExpress} onChange={(e) => setUseExpress(true)} type="checkbox"/> Express.js <div className='cost' title='price of this option'>free</div></div>
                                </div>
                            </div>
                            <div className='ParamItem'>
                                <div className='ItemTitle'>
                                    Database
                                </div>
                                <div className='ItemOptions'>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'mysql' ? true : false} onChange={() => setDatabase('mysql')}/> MySQL <div className='cost' title='price of this option'>$15</div></div>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'postgresql' ? true : false} onChange={() => setDatabase('postgresql')}/> PostgreSQL <div className='cost' title='price of this option'>$29</div></div>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'mongodb' ? true : false} onChange={() => setDatabase('mongodb')}/> MongoDB <div className='cost' title='price of this option'>$35</div></div>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'mariadb' ? true : false} onChange={() => setDatabase('mariadb')}/> MariaDB <div className='cost' title='price of this option'>free</div></div>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'sqlite' ? true : false} onChange={() => setDatabase('sqlite')}/> SQLite <div className='cost' title='price of this option'>free</div></div>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'microsoft_sql_server' ? true : false} onChange={() => setDatabase('microsoft_sql_server')}/> SQL Server <div className='cost' title='price of this option'>free</div></div>
                                    <div className='CheckOption'><input type="checkbox" checked={database === 'dynamo' ? true : false} onChange={() => setDatabase('dynamo')}/> DynamoDB <div className='cost' title='price of this option'>$35</div></div>
                                </div>
                            </div>
                            <div className='ParamItem'>
                                <div className='ItemTitle'>
                                    Auth
                                </div>
                                <div className='ItemOptions'>
                                    <div className='CheckOption'><input value={useJWT} checked={useJWT} onChange={() => setUseJWT(!useJWT)} type="checkbox"/> JWT <div className='cost' title='price of this option'>$8</div></div>
                                    {/* { useJWT &&  <div className='tip'>'users' table will be created automatically</div>} */}
                                </div>
                            </div>
                            {/* доробити оплату за  Pagination і Lambda*/}
                            <div className='ParamItem'>
                                <div className='ItemTitle'>
                                    Common
                                </div>
                                <div className='ItemOptions'>
                                    <div className='CheckOption'><input value={usePagination} checked={usePagination} onChange={() => setUsePagination(!usePagination)} type="checkbox"/> Pagination <div className='cost' title='price of this option'>$8</div></div>
                                    {/* { usePagination &&  <div className='tip'>'users' table will be created automatically</div>} */}
                                </div>
                            </div>
                            <div className='ParamItem'>
                                <div className='ItemTitle'>
                                    Wrap in
                                </div>
                                <div className='ItemOptions'>
                                    <div className='CheckOption'><input value={useLambda} checked={useLambda} onChange={() => setUseLambda(!useLambda)} type="checkbox"/> AWS Lambda <div className='cost' title='price of this option'>$8</div></div>
                                    {/* { useLambda &&  <div className='tip'>'users' table will be created automatically</div>} */}
                                </div>
                            </div>
                            <div className='ParamItem'>
                                {/* <div className='ItemTitle'>
                                    Project Metadata
                                </div> */}
                                {/* <a href="https://www.buymeacoffee.com/daniel.n"><img src={`https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=daniel.n&button_colour=${isDark ? 'ffffff' : '000000'}&font_colour=${isDark ? '000000' : 'ffffff'}&font_family=Cookie&outline_colour=${isDark ? '000000' : 'ffffff'}&coffee_colour=FFDD00`}/></a> */}
                                {/* <div className='ItemOptions'>
                                    <div className='FieldOption'>Name <input placeholder='demo' value={name} onChange={(e) => setName(e.target.value)} className={isDark ? 'field dark' : 'field white'}/></div>
                                    <div className='FieldOption'>Description <input placeholder='demo project' value={description} onChange={(e) => setDescription(e.target.value)} className={isDark ? 'field dark' : 'field white'}/></div>
                                    <div className='FieldOption'>Package name <input placeholder='com.example.demo' value={packageName} onChange={(e) => setPackageName(e.target.value)} className={isDark ? 'field dark' : 'field white'}/></div>
                                    <div className='FieldOption'>Author <input placeholder='your name' value={author} onChange={(e) => setAuthor(e.target.value)} className={isDark ? 'field dark' : 'field white'}/></div>
                                </div> */}
                            </div>
                        </div>
                        <div className='RightBodyPart'>
                            <div className='RightBodyHeader'>
                                <div className='ItemTitle'>
                                    Database Tables
                                </div>
                                <div className={isDark ? 'Button dark' : 'Button white'} onClick={() => setTable(true)}>
                                    ADD TABLE
                                </div>
                            </div>
                            <div className='ListOfTables'>
                                { 
                                    tables.length > 0 ?
                                        tables.map(table => {
                                            return (
                                                <div className='TableRow'>
                                                    <span>{ table.name }</span> <div className={isDark ? 'Button dark' : 'Button white'} onClick={() => removeTable(table)}>REMOVE</div>
                                                </div>
                                            )
                                        })
                                    : 
                                        <p className='EmptyList'>No table created</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={isDark ? 'Footer dark' : 'Footer white'}>
                        { isTrial ? 
                            <button className={isDark ? 'Button dark' : 'Button white'} onClick={() => setOpenPaymentsPopup(true)}>
                                { isPay ? 'WAITING THE PAYMENT' : 'GENERATE FOR 5.00$' }
                            </button>
                            :
                            <button className={isDark ? 'Button dark' : 'Button white'} onClick={() => generate()}>
                                GENERATE FOR FREE
                            </button>
                        }
                    </div>
                </div>
                <div className={isDark ? 'RightMenu dark' : 'RightMenu white'}>
                    <div className={isDark ? 'Theme white' : 'Theme dark'}>
                        <div className={isDark ? 'Sun white' : 'Sun dark'} onClick={() => setDark(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={isDark ? '#1b1f23' : '#fff'}><path d="M0 0h24v24H0z" fill="none"/><path d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"/></svg>
                        </div>
                        <div className={isDark ? 'Moon dark' : 'Moon white'} onClick={() => setDark(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={isDark ? '#fff' : '#1b1f23'}><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><g><path d="M14,2c1.82,0,3.53,0.5,5,1.35C16.01,5.08,14,8.3,14,12s2.01,6.92,5,8.65C17.53,21.5,15.82,22,14,22C8.48,22,4,17.52,4,12 S8.48,2,14,2z"/></g></g></g></svg>
                        </div>
                    </div>
                </div>
                <ToastContainer /></>
            }
        </div>
    )
}

export default Dashboard
  